<template>
  <div class="sconProdList">
    <div class="vg_mb_16">
      <el-button type="primary" plain size="small" @click="clickImport">导入材料</el-button>
      <el-button type="danger" plain size="small" @click="deleteMtrb">删除</el-button>
      <el-button type="primary" v-show="$route.path.indexOf('add') === -1" size="small" @click="refresh">同步材料信息 </el-button>
      <el-button type="primary" size="small" @click="copyRow">复制</el-button>
    </div>
    <!--:row-click="(row, column, event) => currentRowSelectionList(row)"-->
    <DynamicUTable
      ref="SconMtrbList"
      :tableRowKey="'mtrb'"
      :form="sconForm"
      :tableData="tableData"
      :columns="sconMtrbProperties"
      :isShow="isShow"
      :rowMove="true"
      :needPagination="false"
      :needSearch="false"
      :selectable="({ row }) => row.modr_status === 0 || !row.modr_status"
      @selection-change="handleSelectionChange"
    >
      <template v-slot:mtrb_no="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.mtrb_no'">
          <el-input v-model="scope.row.mtrb_no" disabled maxlength="30" show-word-limit placeholder="暂无材料编号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:cust_catalog_number="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.cust_catalog_number'">
          <el-input v-model="scope.row.cust_catalog_number" disabled maxlength="20" show-word-limit placeholder="暂无"></el-input>
        </el-form-item>
      </template>
      <template v-slot:mtrb_unit="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.mtrb_unit'">
          <el-input v-model="scope.row.mtrb_unit" disabled maxlength="30" show-word-limit placeholder="暂无单位"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_use="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_use'">
          <el-input
            @change="prodMtrbPeruseChange(scope.$index)"
            v-model="scope.row.prod_mtrb_use"
            @blur="scope.row.prod_mtrb_use = helper.calcPriceT(scope.row.prod_mtrb_use, 2, 10000)"
            @input="scope.row.prod_mtrb_use = helper.keepTNum1(scope.row.prod_mtrb_use)"
            maxlength="7"
            show-word-limit
            placeholder="暂无耗量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_per="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_per'">
          <el-input
            @change="prodMtrbPeruseChange(scope.$index)"
            v-model="scope.row.prod_mtrb_per"
            @blur="scope.row.prod_mtrb_per = helper.calcPrice2(scope.row.prod_mtrb_per, 2, 10000)"
            @input="scope.row.prod_mtrb_per = helper.keepTNum1(scope.row.prod_mtrb_per)"
            maxlength="9"
            show-word-limit
            placeholder="暂无基数"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_peruse="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_peruse'">
          <el-input v-model="scope.row.prod_mtrb_peruse" disabled maxlength="7" show-word-limit placeholder="暂无单耗比"></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_mtrb_num_predict="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.scon_mtrb_num_predict'">
          <el-input v-model="scope.row.scon_mtrb_num_predict" disabled show-word-limit placeholder="暂无预计数量"></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_mtrb_num="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.scon_mtrb_num'">
          <el-input
            v-model="scope.row.scon_mtrb_num"
            @change="realityOnApply(scope.$index)"
            @input="val => (scope.row.scon_mtrb_num = keepNumber(val))"
            maxlength="10"
            show-word-limit
            placeholder="暂无实际数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_mtrb_applied="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.scon_mtrb_applied'">
          <el-input v-model="scope.row.scon_mtrb_applied" disabled maxlength="7" show-word-limit placeholder="暂无已申购数量"></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_mtrb_apply="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.scon_mtrb_apply'">
          <el-input
            @change="sconMtrbApplyChange(scope.$index)"
            disabled
            v-model="scope.row.scon_mtrb_apply"
            maxlength="7"
            show-word-limit
            placeholder="暂无需申购数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_price="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_price'">
          <el-input
            @change="mtrbTotalPriceChange(scope.$index)"
            v-model="scope.row.prod_mtrb_price"
            @blur="scope.row.prod_mtrb_price = helper.calcPrice(scope.row.prod_mtrb_price, 4, 10000)"
            @input="scope.row.prod_mtrb_price = helper.keepTNum1(scope.row.prod_mtrb_price)"
            maxlength="9"
            show-word-limit
            placeholder="暂无采购单价"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:mtrb_total_price="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.mtrb_total_price'">
          <el-input v-model="scope.row.mtrb_total_price" disabled maxlength="7" show-word-limit placeholder="暂无采购金额"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_mtrb_offer="scope">
        <el-form-item label-width="0" :prop="'scon_mtrb_list.' + scope.$index + '.prod_mtrb_offer'">
          <span v-if="scope.row.prod_mtrb_offer === 1">自供</span>
          <span v-if="scope.row.prod_mtrb_offer === 2">客供</span>
        </el-form-item>
      </template>
      <template v-slot:modr_status="scope">
        <div style="text-align: center">
          <el-tag v-if="scope.row.modr_status === 0" type="danger">未生成</el-tag>
          <el-tag v-else-if="scope.row.modr_status === 1" type="warning">部分生成</el-tag>
          <el-tag v-else-if="scope.row.modr_status === 2" type="success">已生成</el-tag>
          <el-tag v-else type="info">暂无</el-tag>
        </div>
      </template>
      <template v-slot:default-modr_status="scope">
        <div style="text-align: center">
          <el-tag v-if="scope.row.modr_status === 0" type="danger">未生成</el-tag>
          <el-tag v-else-if="scope.row.modr_status === 1" type="warning">部分生成</el-tag>
          <el-tag v-else-if="scope.row.modr_status === 2" type="success">已生成</el-tag>
          <el-tag v-else type="info">暂无</el-tag>
        </div>
      </template>
    </DynamicUTable>
    <el-dialog title="材料" :visible.sync="dialogVisible" width="70%">
      <MtrbImport ref="MtrbImport" @childConfirm="childConfirm" @childChanel="childChanel"></MtrbImport>
    </el-dialog>
  </div>
</template>

<script>
import { get } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import MtrbImport from './MtrbImport.vue';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { BigNumber } from 'bignumber.js';
import { sconAPI } from '@api/modules/scon';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { sconMtrbProperties } from '@/views/SalesManagement/SconManage/scon';
import { cloneDeep } from 'lodash';
import { keep2Decimal, keep6Decimal, keepNumber } from '@assets/js/regExUtil';
import UrlEncode from '@assets/js/UrlEncode';
import { deleteTableRow } from '@assets/js/arrayUtils';

export default {
  name: 'SconMtrbList',
  props: {
    sconForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      default: true
    },
    showFlagNumId: {
      type: Number,
      required: true
    },
    showFlagNumIdCopy: {
      type: Number,
      required: true
    },
    transmitIndex: {
      type: Number,
      required: true
    }
  },
  components: {
    DynamicUTable,
    MtrbImport,
    textEnlargement
  },
  data() {
    return {
      dialogVisible: false,
      selectionsList: [],
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      sconMtrbProperties: cloneDeep(sconMtrbProperties),
      subTableMap: new Map()
    };
  },
  computed: {
    tableData() {
      return this.sconForm.scon_mtrb_list.filter(x =>
        this.showFlagNumIdCopy !== 0 ? x.scon_prod_id === this.showFlagNumIdCopy : x.prod_id === this.showFlagNumId
      );
    }
  },
  created() {
    this.initData();
  },
  methods: {
    keep2Decimal,
    keep6Decimal,
    keepNumber,
    initData() {},
    currentRowSelectionList(row) {
      // this.sconMtrbProperties.find(({ label }) => label === '成品合同号').subItem.options = row.list;
    },
    changeProperties(suppObj) {
      if (suppObj) {
        this.sconMtrbProperties.find(({ label }) => label === '供应商简称').subItem.options = suppObj;
        this.sconMtrbProperties.find(({ label }) => label === '收货工厂').subItem.options = suppObj;
      }
    },
    //需申购数量取实际数量-已申购数量
    realityOnApply(index) {
      //过滤已申购数量null值
      if (!this.tableData[index].scon_mtrb_applied) {
        this.tableData[index].scon_mtrb_applied = 0;
      }
      if (this.tableData[index].scon_mtrb_num && this.tableData[index].scon_mtrb_num !== 0) {
        //计算需申购数量
        this.tableData[index].scon_mtrb_apply = new BigNumber(this.tableData[index].scon_mtrb_num).minus(this.tableData[index].scon_mtrb_applied).toFixed(2, 0);
        this.mtrbTotalPriceChange(index);
      }
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    clickImport() {
      if (this.tableData) {
        this.dialogVisible = true;
        setTimeout(() => {
          this.$refs.MtrbImport.prodId = this.sconForm.scon_prod_list[this.transmitIndex].prod_id;
          this.$refs.MtrbImport.initData();
        }, 100);
      } else {
        this.$message.warning('请先导入成品');
      }
    },
    // 获取供应商
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      let temp = [];
      let idNum = null;
      let prodNum = 0;
      for (let i = 0; i < val.length; i++) {
        for (let j = 0; j < this.tableData.length; j++) {
          if (val[i].mtrb_id === this.tableData[j].mtrb_id && val[i].prod_id === this.tableData[j].prod_id) {
            temp.push(i);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let j of temp) {
        val.splice(j, 1);
      }
      for (let i = 0; i < this.sconForm.scon_prod_list.length; i++) {
        if (this.sconForm.scon_prod_list[i].prod_id === this.sconForm.scon_prod_list[this.transmitIndex].prod_id) {
          idNum = this.sconForm.scon_prod_list[i].scon_prod_id;
          prodNum = this.sconForm.scon_prod_list[i].prod_num;
        }
      }
      for (let i = 0; i < val.length; i++) {
        val[i].scon_mtrb_applied = null;
        val[i].scon_mtrb_apply = null;
        val[i].mtrb_total_price = null;
        // val[i].prod_mtrb_use = 1;
        // val[i].prod_mtrb_per = 1;
        // val[i].prod_mtrb_peruse = 1;
        val[i].scon_prod_id = idNum;
        val[i].prod_num = prodNum;
        val[i].prod_mtrb_price = this.helper.haveFour(val[i].prod_mtrb_price);
        //预计数量
        val[i].scon_mtrb_num_predict = new BigNumber(val[i].prod_num).div(val[i].prod_mtrb_per).times(val[i].prod_mtrb_use).toFixed(0, 0);
        //val[i].scon_mtrb_num_predict = new BigNumber(Number(val[i].prod_mtrb_peruse)).times(Number(val[i].prod_num)).toFixed(0, 0);
        val[i].scon_mtrb_num = val[i].scon_mtrb_num_predict;
        val[i].surplus_price = '0.00';
        val[i].scon_mtrb_apply = val[i].scon_mtrb_num;
        val[i].scon_mtrb_applied = '0.00';
        val[i].mtrb_total_price = new BigNumber(Number(val[i].scon_mtrb_num) * Number(val[i].prod_mtrb_price)).toFixed(4);
      }
      this.sconForm.scon_mtrb_list = this.sconForm.scon_mtrb_list.concat(val);
      this.dialogVisible = false;
    },
    //每一行的回调方法
    rowClassName(row) {
      if (row.row.scon_prod_id !== this.showFlagNumIdCopy) {
        return 'showRow';
      }
    },
    // 删除每一行
    deleteMtrb() {
      if (this.selectionsList.length < 1) {
        return this.$message.error('请至少选择一条数据！');
      }
      let temp = [];
      if (this.showFlagNumIdCopy) {
        temp = this.sconForm.scon_mtrb_list.filter(x => x.scon_prod_id !== this.showFlagNumIdCopy);
      }
      if (this.showFlagNumId) {
        temp = this.sconForm.scon_mtrb_list.filter(x => x.prod_id !== this.showFlagNumId);
      }
      let { tableData, map } = deleteTableRow('scon_mtrb_id', this.tableData, this.subTableMap, this.selectionsList);
      this.sconForm.scon_mtrb_list = temp.concat(tableData);
      this.subTableMap = map;
    },
    // 计算采购金额
    mtrbTotalPriceChange(val) {
      this.tableData[val].mtrb_total_price = new BigNumber(Number(this.tableData[val].scon_mtrb_num) * Number(this.tableData[val].prod_mtrb_price)).toFixed(4);
    },
    // 计算耗比 预计数量 实际数量 需申购数量
    prodMtrbPeruseChange(val) {
      if (val === -1) {
        this.tableData.forEach((item, index) => {
          let { prod_mtrb_use, prod_mtrb_per } = item;
          //预计数量
          this.tableData[index].scon_mtrb_num_predict = new BigNumber(this.sconForm.scon_prod_list[this.transmitIndex].prod_num)
            .div(prod_mtrb_per)
            .times(prod_mtrb_use)
            .toFixed(0, 0);
          //实际数量
          this.tableData[index].prod_mtrb_peruse = new BigNumber(prod_mtrb_use).div(prod_mtrb_per).toFixed(2);
          this.tableData[index].scon_mtrb_num = this.tableData[index].scon_mtrb_num_predict; //实际数量（默认等于预估数量可改）
          //计算需申购数量
          this.sconMtrbApplyChange(index);
          //计算采购金额
          this.mtrbTotalPriceChange(index);
        });
        return;
      }
      let { prod_mtrb_use, prod_mtrb_per } = this.tableData[val];
      //预计数量
      this.tableData[val].scon_mtrb_num_predict = new BigNumber(this.sconForm.scon_prod_list[this.transmitIndex].prod_num)
        .div(prod_mtrb_per)
        .times(prod_mtrb_use)
        .toFixed(0, 0);
      //实际数量
      this.tableData[val].prod_mtrb_peruse = new BigNumber(prod_mtrb_use).div(prod_mtrb_per).toFixed(2);
      this.tableData[val].scon_mtrb_num = this.tableData[val].scon_mtrb_num_predict; //实际数量（默认等于预估数量可改）
      //计算需申购数量
      this.sconMtrbApplyChange(val);
      //计算采购金额
      this.mtrbTotalPriceChange(val);
    },
    // 需申购数量变化
    sconMtrbApplyChange(val) {
      let { scon_mtrb_apply, scon_mtrb_applied, scon_mtrb_num } = this.tableData[val];
      if (scon_mtrb_apply === null) {
        scon_mtrb_apply = 0;
      }
      if (scon_mtrb_applied === null) {
        scon_mtrb_applied = 0;
      }
      this.tableData[val].scon_mtrb_apply = new BigNumber(scon_mtrb_num).minus(scon_mtrb_applied).toFixed(2, 0);
      // if (Number(scon_mtrb_apply) + Number(scon_mtrb_applied) > Number(scon_mtrb_num)) {
      //   this.tableData[val].surplus_price = '0';
      // } else {
      //   this.tableData[val].surplus_price = new BigNumber(scon_mtrb_num ?? 0)
      //     .minus(scon_mtrb_applied ?? 0)
      //     .minus(scon_mtrb_apply ?? 0)
      //     .toFixed(0, 0);
      // }
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.tableData[val1].mtrb_spec;
      } else if (val === 1) {
        this.textEnlargementString.str = this.tableData[val1].mtrb_name;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.tableData[this.textEnlargementNum].mtrb_spec = val;
      } else if (this.textEnlargementFlag === 1) {
        this.tableData[this.textEnlargementNum].mtrb_name = val;
      }
    },
    refresh() {
      if (this.sconForm.scon_prod_list.length === 0) return this.$message.warning('请先导入成品信息!');
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let id = null;
      if (this.transmitIndex) {
        id = this.sconForm.scon_prod_list[this.transmitIndex].prod_id;
      } else {
        id = this.tableData[0]?.prod_id || this.sconForm.scon_prod_list[0].prod_id;
      }
      sconAPI.getSconMtrb({ scon_id: props.form_id, prod_ids: id }).then(async ({ data }) => {
        // data.list.forEach(item => {
        //   let find = this.sconForm.scon_mtrb_list.find(x => x.scon_prod_id === item.scon_prod_id && x.mtrb_id === item.mtrb_id);
        //   if (find) {
        //     find.mtrb_unit = item.mtrb_unit;
        //     find.cust_catalog_number = item.cust_catalog_number;
        //     find.mtrb_name = item.mtrb_name;
        //     find.prod_mtrb_price = item.prod_mtrb_price;
        //     find.supp_id = item.supp_id;
        //     find.mtrb_spec = item.mtrb_spec;
        //   }
        // });
        // this.sconForm.scon_mtrb_list = this.sconForm.scon_mtrb_list.concat(data.newlist);
        // let index = this.sconForm.scon_prod_list.findIndex(x => x.prod_id === this.tableData[0].prod_id);
        await this.$set(this.sconForm, 'scon_mtrb_list', this.sconForm.scon_mtrb_list.filter(x => x.prod_id !== id).concat(data.list));
        // await this.$emit('syncPartMtrbData', index);
      });
    },
    copyRow() {
      if (this.selectionsList.length === 0 || this.selectionsList.length > 1) return this.$message.warning('请选择一条数据!');
      this.$prompt('请确认复制数量', '复制', {
        confirmButtonText: '确定复制',
        cancelButtonText: '取消',
        inputPattern: /^[\d.]+$/,
        inputErrorMessage: '请输入正确的数量'
      }).then(({ value }) => {
        if (value > 10) {
          this.$message({
            type: 'error',
            message: '单次只能复制10份！'
          });
          return;
        }
        for (let i = 0; i < value; i++) {
          let deepData = cloneDeep(this.selectionsList[0]);
          deepData.scon_mtrb_id = null;
          deepData.scon_mtrb_applied = 0; //已申购数量
          deepData.scon_mtrb_apply = deepData.scon_mtrb_num; //需申购数量
          delete deepData._XID;
          this.sconForm.scon_mtrb_list.push(deepData);
        }
        this.$refs.SconMtrbList.clearSelection();
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
</style>
