<template>
  <div class="sconProdList">
    <div class="vg_mb_16">
      <el-button type="primary" plain size="small" @click="clickImport">导入部件</el-button>
      <el-button type="danger" plain size="small" @click="deletePart">删除</el-button>
      <!--<el-tooltip class="item" effect="dark" content="同步后清空成品数量" placement="top">-->
      <el-button type="primary" v-show="$route.path.indexOf('add') === -1" size="small" @click="refresh">同步部件信息 </el-button>
      <!--</el-tooltip>-->
      <el-button type="primary" size="small" @click="copyRow">复制</el-button>
      <el-button type="success" size="small" class="vg_mt_8" @click="exportExcel()" :disabled="!isShow">导出Excel</el-button>
    </div>
    <DynamicUTable
      ref="SconPartList"
      :form="sconForm"
      :tableData="tableData"
      :columns="sconPartProperties"
      :isShow="isShow"
      :rowMove="true"
      :needPagination="false"
      :needSearch="false"
      :selectable="({ row }) => row.create_status === 0 || !row.create_status"
      @selection-change="handleSelectionChange"
      :change-load="true"
    >
      <template v-slot:prod_mainsup="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_mainsup'">
          <el-radio v-model="scope.row.prod_mainsup" :label="1">是</el-radio>
          <el-radio v-model="scope.row.prod_mainsup" :label="0">否</el-radio>
        </el-form-item>
      </template>
      <template v-slot:default-prod_mainsup="scope">
        <span v-if="scope.row.prod_mainsup === 1">是</span>
        <span v-if="scope.row.prod_mainsup === 0">否</span>
      </template>
      <template v-slot:prod_no="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_no'">
          <el-input v-model="scope.row.prod_no" disabled maxlength="30" show-word-limit placeholder="暂无我司货号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_poststfix="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_poststfix'">
          <el-input v-model="scope.row.prod_poststfix" disabled maxlength="2" show-word-limit placeholder="暂无货号后缀"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_cust_no="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_cust_no'">
          <el-input v-model="scope.row.prod_cust_no" show-word-limit placeholder="暂无客户货号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_unit="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_unit'">
          <el-select filterable v-model="scope.row.prod_unit" placeholder="请选择外包装单位">
            <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_use="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_use'">
          <el-input
            @change="sconPartNumChange(scope.$index)"
            v-model="scope.row.prod_use"
            @blur="scope.row.prod_use = helper.calcPrice2(scope.row.prod_use, 2, 10000)"
            @input="scope.row.prod_use = helper.keepTNum1(scope.row.prod_use)"
            maxlength="9"
            show-word-limit
            placeholder="暂无用量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_part_num="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.scon_part_num'">
          <el-input v-model="scope.row.scon_part_num" disabled show-word-limit placeholder="暂无预计数量"></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_part_num_reality="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.scon_part_num_reality'">
          <el-input
            v-model="scope.row.scon_part_num_reality"
            @change="realityOnApply(scope.$index)"
            @input="val => (scope.row.scon_part_num_reality = keep2Decimal(val))"
            show-word-limit
            placeholder="暂无实际数量"
            maxlength="10"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_part_applied="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.scon_part_applied'">
          <el-input v-model="scope.row.scon_part_applied" disabled show-word-limit placeholder="暂无已申购数量"></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_part_apply="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.scon_part_apply'">
          <el-input
            disabled
            @change="totalPriceChange(scope.$index, 0)"
            v-model="scope.row.scon_part_apply"
            @input="scope.row.scon_part_apply = helper.keepTNum(scope.row.scon_part_apply)"
            maxlength="10"
            show-word-limit
            placeholder="暂无需申购数量"
          ></el-input>
          <!-- :disabled="!scope.row.scon_part_num" -->
        </el-form-item>
      </template>
      <template v-slot:prod_price="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.prod_price'">
          <el-input
            @change="totalPriceChange(scope.$index, 1)"
            v-model="scope.row.prod_price"
            @input="scope.row.prod_price = helper.keepTNum1(scope.row.prod_price)"
            @blur="scope.row.prod_price = helper.calcPrice(scope.row.prod_price, 4, 10000)"
            maxlength="9"
            show-word-limit
            placeholder="暂无采购单价"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:total_price="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.total_price'">
          <el-input v-if="flagShow" v-model="scope.row.total_price" disabled show-word-limit placeholder="暂无采购金额"></el-input>
        </el-form-item>
      </template>
      <template v-slot:statistics="scope">
        <el-form-item label-width="0" :prop="'scon_part_list.' + scope.$index + '.statistics'">
          <el-select filterable v-model="scope.row.statistics" placeholder="请选择是否统计">
            <el-option v-for="item in statisticsList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:create_status="scope">
        <div style="text-align: center">
          <el-tag v-if="scope.row.create_status === 0" type="danger">未生成</el-tag>
          <el-tag v-else-if="scope.row.create_status === 1" type="warning">部分生成</el-tag>
          <el-tag v-else-if="scope.row.create_status === 2" type="success">已生成</el-tag>
          <el-tag v-else type="info">暂无</el-tag>
        </div>
      </template>
    </DynamicUTable>
    <el-dialog title="部件" :visible.sync="dialogVisible" width="70%">
      <PartImport ref="PartImport" @childConfirm="childConfirm" @childChanel="childChanel"></PartImport>
    </el-dialog>
  </div>
</template>

<script>
import PartImport from './PartImport.vue';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { sconAPI } from '@api/modules/scon';
import { cloneDeep } from 'lodash';
import { deleteTableRow } from '@assets/js/arrayUtils';
import { downloadFile } from '@api/public';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { sconPartProperties } from '@/views/SalesManagement/SconManage/scon';
import { BigNumber } from 'bignumber.js';
import { keep2Decimal, keepNumber } from '@assets/js/regExUtil';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SconPartList',
  props: {
    sconForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      default: true
    },
    showFlagNumId: {
      type: Number,
      required: true
    },
    showFlagNumIdCopy: {
      type: Number,
      required: true
    },
    transmitIndex: {
      type: Number,
      required: true
    },
    prodUnitList: {
      type: Array,
      required: true
    }
  },
  components: {
    DynamicUTable,
    PartImport,
    textEnlargement
  },
  data() {
    return {
      sconPartProperties: cloneDeep(sconPartProperties),
      dialogVisible: false,
      selectionsList: [],
      value1: '是',
      value2: '否',
      flagShow: true,
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      statisticsList: [
        { value: 0, label: '否' },
        { value: 1, label: '是' }
      ],
      suppList: {},
      subTableMap: new Map()
    };
  },
  computed: {
    tableData() {
      if (this.showFlagNumIdCopy) {
        return this.sconForm.scon_part_list.filter(x => x.scon_prod_id === this.showFlagNumIdCopy);
      }
      if (this.showFlagNumId) {
        return this.sconForm.scon_part_list.filter(x => x.prod_id === this.showFlagNumId);
      }
      return [];
    }
  },
  mounted() {
    this.initData();
  },
  created() {
    //只有业务5部能看到指定供应商字段
    let { dept_id } = this.$cookies.get('userInfo');
    if (dept_id === 28) {
      this.sconPartProperties.find(({ prop }) => prop === 'designate_supp_id').visible = true;
    }
  },
  methods: {
    keep2Decimal,
    keepNumber,
    initData() {
      this.changeProperties();
    },
    changeProperties(suppObj) {
      if (suppObj) {
        this.sconPartProperties[0].subItem.options = suppObj;
        this.sconPartProperties[1].subItem.options = suppObj;
      }
    },
    buttonShipstat(item) {
      if (item === 0) {
        return { name: '未生成', type: 'danger' };
      } else if (item === 1) {
        return { name: '部分生成', type: 'warning' };
      } else if (item === 2) {
        return { name: '已生成', type: 'success' };
      }
    },
    //需申购数量取实际数量-已申购数量
    realityOnApply(index) {
      //过滤已申购数量null值
      if (!this.tableData[index].scon_part_applied) {
        this.tableData[index].scon_part_applied = 0;
      }
      if (this.tableData[index].scon_part_num_reality && this.tableData[index].scon_part_num_reality !== 0) {
        this.tableData[index].scon_part_apply = new BigNumber(this.tableData[index].scon_part_num_reality - this.tableData[index].scon_part_applied).toFixed(
          2,
          0
        );
        //计算采购金额
        this.totalPriceChange(index, 0);
      }
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    clickImport() {
      if (this.sconForm.scon_prod_list.length > 0) {
        this.dialogVisible = true;
        setTimeout(() => {
          this.$refs.PartImport.prodId = this.sconForm.scon_prod_list[this.transmitIndex].prod_id;
          // this.$refs.PartImport.prodId = 10;
          this.$refs.PartImport.initData();
        }, 100);
      } else {
        this.$message.warning('请先导入成品');
      }
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    // 确认传值
    childConfirm(value) {
      let val = [...value];
      let idNum = null;
      let prodNum = 0;

      val = val.filter(item => this.tableData.every(item1 => item.part_id !== item1.part_id));

      const prodList = this.sconForm.scon_prod_list.find(item => item.prod_id === this.sconForm.scon_prod_list[this.transmitIndex].prod_id);
      if (prodList) {
        idNum = prodList.scon_prod_id;
        prodNum = prodList.prod_num;
      }

      val.forEach(item => {
        item.scon_part_applied = 0;
        item.surplus_price = 0;
        item.scon_prod_id = idNum;
        item.prod_num = prodNum;
        item.scon_part_num = new BigNumber(item.scon_part_num).toFixed(0, 0);
        item.scon_part_num_reality = item.scon_part_num;
        item.scon_part_apply = new BigNumber(item.scon_part_num_reality).minus(item.scon_part_applied).toFixed(2, 0);
        item.total_price = new BigNumber(Number(item.scon_part_apply) * Number(item.prod_price)).toFixed(4);
        //计算导入的数据
        this.computedImportData(item);
      });
      this.sconForm.scon_part_list = [...this.sconForm.scon_part_list, ...val];
      this.dialogVisible = false;
    },

    //每一行的回调方法
    rowClassName(row) {
      if (row.row.scon_prod_id !== this.showFlagNumIdCopy) {
        return 'showRow';
      }
    },
    //计算导入的数据
    computedImportData(item) {
      item.scon_part_num = new BigNumber(item.prod_use).times(this.sconForm.scon_prod_list[this.transmitIndex].prod_num).toFixed(2, 0); // 向上取整
      item.scon_part_num_reality = item.scon_part_num;
      //过滤已申购数量null值
      if (!item.scon_part_applied) {
        item.scon_part_applied = 0;
      }
      //需申购数量 = 实际数量 - 已申购数量
      item.scon_part_apply = new BigNumber(item.scon_part_num_reality).minus(item.scon_part_applied).toFixed(2, 0);
      //计算金额
      this.flagShow = false;
      item.total_price = new BigNumber(Number(item.scon_part_num_reality) * Number(item.prod_price)).toFixed(4);
      item.surplus_price = new BigNumber(item.scon_part_num).minus(item.scon_part_applied).minus(item.scon_part_apply).toFixed(4);
      this.flagShow = true;
    },
    // 删除每一行
    deletePart() {
      if (this.selectionsList.length < 1) {
        return this.$message.error('请至少选择一条数据！');
      }

      let temp = [];
      if (this.showFlagNumIdCopy) {
        temp = this.sconForm.scon_part_list.filter(x => x.scon_prod_id !== this.showFlagNumIdCopy);
      }
      if (this.showFlagNumId) {
        temp = this.sconForm.scon_part_list.filter(x => x.prod_id !== this.showFlagNumId);
      }
      let { tableData, map } = deleteTableRow('scon_part_id', this.tableData, this.subTableMap, this.selectionsList);
      this.sconForm.scon_part_list = temp.concat(tableData);
      this.subTableMap = map;
    },
    copyRow() {
      if (this.selectionsList.length === 0 || this.selectionsList.length > 1) return this.$message.warning('请选择一条数据!');
      let deepData = cloneDeep(this.selectionsList[0]);
      deepData.scon_part_id = null;
      delete deepData._XID;
      this.sconForm.scon_part_list.unshift(deepData);
      this.$refs.SconPartList.clearSelection();
    },
    // 计算预计数量
    sconPartNumChange(val) {
      this.tableData[val].scon_part_num = new BigNumber(this.tableData[val].prod_use)
        .times(this.sconForm.scon_prod_list[this.transmitIndex].prod_num)
        .toFixed(2, 0); // 向上取整
      this.tableData[val].scon_part_num_reality = this.tableData[val].scon_part_num;
      //过滤已申购数量null值
      if (!this.tableData[val].scon_part_applied) {
        this.tableData[val].scon_part_applied = 0;
      }
      //需申购数量 = 实际数量 - 已申购数量
      this.tableData[val].scon_part_apply = new BigNumber(this.tableData[val].scon_part_num_reality).minus(this.tableData[val].scon_part_applied).toFixed(2, 0);
      //计算采购金额
      this.totalPriceChange(val, 0);
    },
    // 计算采购金额
    totalPriceChange(val, val1) {
      if (val1 === 0) {
        if (Number(this.tableData[val].scon_part_apply) + Number(this.tableData[val].scon_part_applied) > Number(this.tableData[val].scon_part_num)) {
          this.tableData[val].scon_part_apply = new BigNumber(this.tableData[val].scon_part_num_reality)
            .minus(this.tableData[val].scon_part_applied)
            .toFixed(2, 0);
          // this.tableData[val].scon_part_apply = parseInt(
          //   (Number(this.tableData[val].scon_part_num) * 100000 - Number(this.tableData[val].scon_part_applied) * 100000) / 100000
          // );
          this.tableData[val].total_price = new BigNumber(Number(this.tableData[val].scon_part_num_reality) * Number(this.tableData[val].prod_price)).toFixed(
            4
          );
          this.tableData[val].surplus_price = 0;
        } else {
          this.flagShow = false;
          let price = '';
          price = new BigNumber(Number(this.tableData[val].scon_part_num_reality) * Number(this.tableData[val].prod_price)).toFixed(4);
          this.tableData[val].total_price = price;
          this.tableData[val].surplus_price =
            (Number(this.tableData[val].scon_part_num) * 100000 -
              Number(this.tableData[val].scon_part_applied) * 100000 -
              Number(this.tableData[val].scon_part_apply) * 100000) /
            100000;
          this.flagShow = true;
        }
      } else if (val1 === 1) {
        this.tableData[val].total_price = new BigNumber(Number(this.tableData[val].scon_part_num_reality) * Number(this.tableData[val].prod_price)).toFixed(4);
      }
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.tableData[val1].prod_desc;
      } else if (val === 1) {
        this.textEnlargementString.str = this.tableData[val1].prod_name;
      } else if (val === 2) {
        this.textEnlargementString.str = this.tableData[val1].prod_spec;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.tableData[this.textEnlargementNum].prod_desc = val;
      } else if (this.textEnlargementFlag === 1) {
        this.tableData[this.textEnlargementNum].prod_name = val;
      } else if (this.textEnlargementFlag === 2) {
        this.tableData[this.textEnlargementNum].prod_spec = val;
      }
    },
    refresh() {
      if (this.sconForm.scon_prod_list.length === 0) return this.$message.warning('请先导入成品信息!');
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let id = null;
      if (this.transmitIndex) {
        id = this.sconForm.scon_prod_list[this.transmitIndex].prod_id;
      } else {
        id = this.tableData[0]?.prod_id || this.sconForm.scon_prod_list[0].prod_id;
      }
      sconAPI.getProduct({ scon_id: props.form_id, prod_ids: id }).then(async ({ data }) => {
        // let index = this.sconForm.scon_prod_list.findIndex(x => x.prod_id === this.tableData[0].prod_id);
        await this.$set(this.sconForm, 'scon_part_list', this.sconForm.scon_part_list.filter(x => x.prod_id !== id).concat(data.list));
        // await this.$emit('syncPartMtrbData', index);
      });
    },
    exportExcel() {
      let requestBody = { type: 1, scon_id: this.sconForm.scon_id, scon_no: this.sconForm.scon_no };
      sconAPI.exportPartStatistics(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: this.sconForm.scon_no, type: '.xlsx' });
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
</style>
