<template>
  <div class="sconProdList">
    <div class="vg_mb_16">
      <el-button type="primary" plain size="small" @click="instCatnip">导入猫草</el-button>
      <el-button type="danger" plain size="small" @click="clickDel">删除</el-button>
      <el-button type="primary" v-show="$route.path.indexOf('add') === -1" plain size="small" @click="syncCatnip"> 同步猫草 </el-button>
    </div>
    <DynamicUTable
      ref="SconCatnipList"
      :tableRowKey="'Catnip'"
      :form="sconForm"
      :tableData="tableData"
      :columns="sconCatnipProperties"
      :isShow="isShow"
      :rowMove="true"
      :needPagination="false"
      :needSearch="false"
      @selection-change="handleSelectionChange"
    >
      <template v-slot:catnip_dosage="scope">
        <el-form-item label-width="0" :prop="'scon_catnip_list.' + scope.$index + '.catnip_dosage'">
          <el-input
            @change="sconCatnipNumChange(scope.$index)"
            v-model="scope.row.catnip_dosage"
            @blur="scope.row.catnip_dosage = helper.calcPriceT(scope.row.catnip_dosage, 2, 10000)"
            @input="scope.row.catnip_dosage = helper.keepTNum1(scope.row.catnip_dosage)"
            maxlength="5"
            show-word-limit
            placeholder="暂无用量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:mtrb_unit="scope">
        <el-form-item label-width="0" :prop="'scon_catnip_list.' + scope.$index + '.mtrb_unit'">
          <el-select filterable v-model="scope.row.mtrb_unit" placeholder="请选择单位">
            <el-option v-for="item in prodUnitList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </template>
    </DynamicUTable>
    <el-dialog title="选择猫草" :visible.sync="dialogVisible" width="50%">
      <div style="margin-bottom: 10px">
        <el-button type="primary" plain size="small" @click="refreshInstCatnip">刷新</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_cursor"
            ref="multiTable"
            :data="tableData2"
            @selection-change="handleSelectionChange2"
            :row-key="row => row.id"
            border
            v-loading="loadFlag"
          >
            <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
            <el-table-column label="序号" width="120" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="猫草种类" prop="catnip_type"></el-table-column>
            <el-table-column label="英文名称" prop="mtrb_ename"></el-table-column>
            <el-table-column label="供应商简称" prop="supp_abbr"></el-table-column>
          </el-table>
          <div style="text-align: center; margin-top: 20px">
            <el-button type="primary" plain size="small" @click="clickAdd">确认导入</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { sconCatnipProperties } from '@/views/SalesManagement/SconManage/scon';
import PartImport from '@/views/SalesManagement/SconManage/TabChild/Component/PartImport.vue';
import { get } from '@api/request';
import { mtrbAPI } from '@api/modules/mtrb';
import PubPagination from '@/components/common/pubPagination.vue';
import helper from '@assets/js/helper';
import { BigNumber } from 'bignumber.js';
import UrlEncode from '@assets/js/UrlEncode';
import { sconAPI } from '@api/modules/scon';

export default {
  name: 'SconCatnipList',
  components: { PubPagination, PartImport, DynamicUTable },
  props: {
    sconForm: Object,
    isShow: {
      type: Boolean,
      default: true
    },
    transmitIndex: {
      type: Number,
      required: true
    },
    showFlagNumId: {
      type: Number,
      required: true
    },
    showFlagNumIdCopy: {
      type: Number,
      required: true
    },
    prodUnitList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tableData2: [],
      sconCatnipProperties: cloneDeep(sconCatnipProperties),
      selectionsList: [],
      selectionsList2: [],
      delList: [],
      dialogVisible: false,
      totalPage: 0,
      currentPage: 1,
      loadFlag: true,
      suppList: {}
    };
  },
  computed: {
    helper() {
      return helper;
    },
    tableData() {
      if (this.showFlagNumIdCopy) {
        // let prodId = this.sconForm.scon_prod_list.filter(x => x.scon_prod_id === this.showFlagNumIdCopy)[0].prod_id;
        return this.sconForm.scon_catnip_list.filter(x => x.scon_prod_id === this.showFlagNumIdCopy);
      }
      if (this.showFlagNumId) {
        return this.sconForm.scon_catnip_list.filter(x => x.prod_id === this.showFlagNumId);
      }
      return [];
    }
  },
  methods: {
    //计算猫草数量
    sconCatnipNumChange(val) {
      let catnip_dosage = this.tableData[val].catnip_dosage ? Number(this.tableData[val].catnip_dosage) : 0;
      let prod_num = this.sconForm.scon_prod_list[this.transmitIndex].prod_num ? this.sconForm.scon_prod_list[this.transmitIndex].prod_num : 0;
      this.tableData[val].catnip_num = new BigNumber(Number(catnip_dosage)).times(Number(prod_num)).toFixed(2, 0); // 向上取整
    },
    changeProperties(suppObj) {
      if (suppObj) {
        this.sconCatnipProperties[5].subItem.options = suppObj;
      }
    },
    //猫草多选
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    //导入猫草多选
    handleSelectionChange2(selection) {
      this.selectionsList2 = selection;
    },
    //刷新猫草
    refreshInstCatnip() {
      this.loadFlag = true;
      this.instCatnip();
    },
    //导入猫草
    instCatnip() {
      this.dialogVisible = true;
      //获取导入猫草数据
      get(mtrbAPI.get_scon_mtrb_catnip, {
        page_no: this.currentPage,
        prod_id: this.sconForm.scon_prod_list[this.transmitIndex].prod_id
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData2 = res.data.data.list;
            this.totalPage = res.data.data.total;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.log(err));
    },
    //确认导入猫草
    clickAdd() {
      if (this.sconForm.scon_prod_list.length > 0) {
        let val = cloneDeep(this.selectionsList2);
        val = val.filter(item => this.tableData.every(item1 => item.mtrb_id !== item1.mtrb_id));
        let scon_prod = this.sconForm.scon_prod_list[this.transmitIndex];
        val.forEach(item => {
          // item.prod_id = scon_prod.prod_id;
          item.scon_prod_id = scon_prod.scon_prod_id;
          const product = new BigNumber(scon_prod.prod_num).times(item.catnip_dosage);
          item.catnip_num = product.isFinite() ? product.toFixed(2, 0) : 0;
        });
        this.sconForm.scon_catnip_list = this.sconForm.scon_catnip_list.concat(val);
        this.dialogVisible = false;
        this.$refs.multiTable.clearSelection();
      } else {
        this.$message.warning('请先导入成品');
        this.selectionsList2 = [];
        this.$refs.multiTable.clearSelection();
      }
    },
    //同步猫草
    syncCatnip() {
      if (this.sconForm.scon_prod_list.length === 0) return this.$message.warning('请先导入成品信息!');
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let id = null;
      if (this.transmitIndex) {
        id = this.sconForm.scon_prod_list[this.transmitIndex].prod_id;
      } else {
        id = this.tableData[0]?.prod_id || this.sconForm.scon_prod_list[0].prod_id;
      }
      sconAPI.syncCatnip({ scon_id: props.form_id, prod_ids: id }).then(async ({ data }) => {
        this.$set(this.sconForm, 'scon_catnip_list', this.sconForm.scon_catnip_list.filter(x => x.prod_id !== id).concat(data.list));
      });
    },
    //删除
    clickDel() {
      if (this.selectionsList.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      this.$confirm('确定移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.selectionsList.forEach((item, index) => {
            this.sconForm.scon_catnip_list.forEach((item2, index2) => {
              if (item === item2) {
                // if (item2.destroy_flag !== undefined) {
                // if (item2.scon_catnip_id) {
                //   item2.destroy_flag = 1;
                //   this.delList.push(item2);
                // }
                // }
                this.sconForm.scon_catnip_list.splice(index2, 1);
              }
            });
          });
        })
        .catch(err => console.error(err));
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.instCatnip();
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}
</style>
